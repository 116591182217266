import { jsx as _jsx } from "react/jsx-runtime";
import { Stack } from '@mui/material';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useScrollSpy } from '../../../../utils/useScrollSpy';
import NavList from './NavList';
// ----------------------------------------------------------------------
export default function NavDesktop({ isOffset, data }) {
    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const location = useLocation();
    const ids = ['categories', 'about', 'location', 'testimonials'];
    const activeId = useScrollSpy(ids, 70);
    useEffect(() => {
        scrollToSection(location.pathname.split('/')[1]);
    }, [location.pathname, location.key]);
    return (_jsx(Stack, { component: "nav", direction: "row", spacing: 5, sx: { mr: 5, height: 1 }, children: data.map((link) => (_jsx(NavList, { item: link, isOffset: isOffset, activeId: activeId }, link.title))) }));
}
